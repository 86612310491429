import { AsyncState } from 'state-domains/types';

import { MXDTimestamp } from '../utils';

export interface EventsSubState extends AsyncState {
    items: EventsObject[];
    count: number;
}
export interface PostsSubState extends AsyncState {
    items: PostsObject[];
    count: number;
}
export interface EventActionState extends AsyncState {
    shouldReload: boolean;
}
export interface JobState extends AsyncState {
    jobs: Record<string, Job>;
    searchTerm?: string;
    offset?: number;
    limit?: number;
    eventsState: EventsSubState;
    postsState: PostsSubState;
    eventActionState: EventActionState;
}

interface JobUser {
    user: string;
    id: string;
    createdBy?: string;
    createdAt?: MXDTimestamp;
}

export type JobUsers = Record<string, JobUser>;

export interface Job {
    id: string;
    parameters: {
        body: {
            project?: string;
            destination?: string[];
            exportTableIds?: { tableIds: string[]; tableViewIds: string[] };
        };
    };
    progressPercentage?: number;
    name: string;
    error: string;
    createdAt?: MXDTimestamp;
    createdBy?: string;
    status: string;
    result?: string;
    type: string;
    evoObjectLink?: string;
}

export interface Jobs {
    Job: Job[];
}

export interface JobError {
    error: Error;
}

export interface JobCompleteEvent {
    detail: string;
    referenceDetail: string;
    detail1: string;
}

export enum JobType {
    Export = 'Export',
    ListExport = 'List-Export',
    Import = 'Import',
    TableExport = 'Table-Export',
    RankedColumnRecompute = 'Ranked-Column-Recompute',
    Recalculation = 'Recalculation',
}

export enum JobStatus {
    FAILED = 'Failed',
    EXPIRED = 'Expired',
    CANCELLED = 'Cancelled',
    SUCCEEDED = 'Succeeded',
    SUBMITTED = 'Submitted',
    PENDING = 'Pending',
    RUNNABLE = 'Runnable',
    STARTING = 'Starting',
    RUNNING = 'Running',
}

export interface EventsObject {
    id: string;
    activity: string | null;
    description: string | null;
    detail: string;
    detail1: string;
    eventType: string;
    linkedId: string | null;
    linkedName: string | null;
    linkedType: string | null;
    name: string;
    project: string | null;
    referenceAncestors: Record<
        string,
        {
            type: string;
            id: string;
            name: string;
        }
    > | null;
    referenceDetail: string | null;
    referenceId: string | null;
    referenceName: string | null;
    referenceType: string | null;
    updatedByUser: {
        profile: {
            name: string;
            initials: string;
            color: string;
        };
    };
    createdBy: string;
    createdAt: MXDTimestamp;
    updatedBy: string;
    updatedAt: MXDTimestamp;
    post: PostsObject;
}

export interface PostsObject {
    id: string;
    type: string;
    content: string;
    attachments: Record<string, PostsAttachment>;
    userId: string;
    replies: PostReply[];
    linkedObject?: PostsLinkedObject;
    shareType: string;
    shareWith: string[];
    taggedUsers: string[];
    postedDatetime: MXDTimestamp;
    updatedBy: string;
    updatedAt: MXDTimestamp;
}

export interface PostsAttachment {
    contentType: string;
    createdAt: MXDTimestamp;
    createdBy: string;
    file: string;
    id: string;
    originalFilename: string;
    size: number;
    updatedAt: MXDTimestamp;
    updatedBy: string;
}

export interface PostReply {
    userId: string;
    content: string;
    postedDateTime: MXDTimestamp;
    taggedUsers: any[];
}

export interface PostsLinkedObject {
    project: string;
    activity: string;
    object: string;
    objectName: string;
    objectType: string;
    collar: string;
}
